.table-modern.no-border td,
.table-modern.no-border th {
  border: none !important;
}

.bg-secondary {
  background-color: #3a3f44 !important;
  /* Cor personalizada para cabeçalho */
}

.table-modern th,
.table-modern td {
  vertical-align: middle;
  padding: 12px 8px;
}

button {
  padding: 4px 6px;
}

/* Estilo base para os botões de ação */
.action-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Efeito de hover moderno */
.action-btn:hover {
  transform: scale(1.1);
  background-color: #e0f7fa;
  /* Suave cor de fundo ao passar o mouse */
}

/* Botão de edição com cor diferenciada */
.edit-btn {
  color: #4caf50;
  /* Verde */
}

.edit-btn:hover {
  background-color: rgba(76, 175, 80, 0.2);
  color: #388e3c;
}

/* Botão de exclusão com estilo de alerta */
.delete-btn {
  color: #f44336;
  /* Vermelho */
}

.delete-btn:hover {
  background-color: rgba(244, 67, 54, 0.2);
  color: #d32f2f;
}

/* Removendo o foco azul ao clicar */
.action-btn:focus {
  outline: none;
  box-shadow: none;
}
