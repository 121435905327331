.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    text-align: center;
  }
  
  .error-content {
    max-width: 600px;
    padding: 40px;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.8s ease-in-out;
  }
  
  .error-image {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    animation: float 3s ease-in-out infinite;
  }
  
  .error-title {
    font-size: 2.5rem;
    color: #343a40;
    margin-bottom: 15px;
  }
  
  .error-message {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 30px;
  }
  
  .home-button {
    font-size: 1.1rem;
    padding: 10px 20px;
    animation: pulse 1.5s infinite;
  }
  
  /* Animações */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
  }

  
  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  