/* src/components/Login.css */

.login-container {
    margin-top: 50px;
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
  }
  
  button {
    background-color: #007bff;
    border: none;
    padding: 10px;
    border-radius: 5px;
  }
  