  .hero-container-services {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .imagem-heroi-services {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .hero-overlay-services {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semitransparente */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-text-services {
    text-align: center;
    color: #ffffff; /* Texto branco */
  }
  
  .hero-text-services h1 {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    .hero-text-services h1 {
      font-size: 1.8rem; /* Tamanho reduzido para tablets e telas menores */
    }
  }
  
  @media (max-width: 576px) {
    .hero-text-services h1 {
      font-size: 1.5rem; /* Tamanho ainda menor para celulares */
    }
  }

  .icon-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #12747D;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Centraliza o círculo no col */
  }

  .icon-circle:hover{
    transform: scale(1.1); /* Aumenta o tamanho do ícone */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombra para dar um efeito de destaque */
 }

  .ver-mais {
    color: #a9a9a9; /* Cinza claro */
    cursor: pointer;
    text-decoration: underline;
  }
  
  .ver-mais:hover {
    color: #808080; /* Cinza escuro ao passar o mouse */
  }

  /* modals.css */

/* Modal Container */
.custom-modal .modal-content {
    border-radius: 15px;
    background-color: #f7f7f7;
    border: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal Header */
  .modal-header {
    background-color: #007bff;
    color: #fff;
    border-bottom: none;
    padding: 15px;
    border-radius: 15px 15px 0 0;
  }
  
  /* Modal Body */
  .modal-body {
    padding: 30px;
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
  }
  
  /* Modal Footer */
  .modal-footer {
    border-top: none;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    background-color: #f7f7f7;
    border-radius: 0 0 15px 15px;
  }
  
  /* Close Button */
  .close-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 1rem;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .close-button:hover {
    background-color: #218838;
  }
  
  /* Image container */
  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  

  
  