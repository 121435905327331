/* Calendar.css */
body {
  font-family: 'Arial', sans-serif;
  /* Use a cleaner font for better readability */
}

/* General container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  /* Center the container */
  padding: 20px;
}

/* Sidebar styles */
.sidebar {
  background-color: #f0f2f5;
  /* Light background for contrast */
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  /* Light border for separation */
}

.sidebar h4 {
  font-size: 1.5rem;
  /* Increase heading size */
  margin-bottom: 20px;
}

.sidebar button {
  width: 100%;
  /* Full-width buttons */
  margin-top: 10px;
}

/* Card styles */
.card {
  border: none;
  /* Remove default card border */
  border-radius: 10px;
  /* Rounded corners */
  margin-top: 20px;
}

/* FullCalendar styles */
.fc {
  font-family: 'Arial', sans-serif;
  /* Ensure consistent font across calendar */
}

.fc-toolbar {
  background-color: #12747D;
  /* Change toolbar background color */
  color: white;
  /* Toolbar text color */
  border-radius: 10px;
  /* Rounded corners for toolbar */
  padding: 10px;
  /* Padding for toolbar */
}

.fc-toolbar button {
  color: white;
  /* Button text color */
}

.fc-daygrid-day {
  background-color: #ffffff;
  /* White background for calendar days */
  border: 1px solid #e0e0e0;
  /* Light border for calendar cells */
  border-radius: 5px;
  /* Rounded corners for cells */
}

.fc-daygrid-day:hover {
  background-color: #f7f9fc;
  /* Light hover effect for cells */
}

.fc-event {
  border-radius: 5px;
  /* Rounded corners for events */
  padding: 5px;
  /* Padding inside events */
  text-align: center;
  /* Center text in events */
  color: white;
  /* White text for events */
  background-color: #12747D;
}

.fc-event.green {
  background-color: #12747D;
  /* Green for available slots */
}

.fc-event.gray {
  background-color: #6c757d;
  /* Gray for unavailable slots */
}

/* Modal styles */
.modal-header {
  background-color: #12747D;
  /* Modal header background */
  color: white;
  /* Modal header text color */
}

.modal-footer {
  background-color: #f0f2f5;
  /* Light background for footer */
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    border-right: none;
    /* Remove border on smaller screens */
    border-bottom: 1px solid #e0e0e0;
    /* Add bottom border for separation */
  }

  .container {
    padding: 10px;
    /* Reduce padding on smaller screens */
  }
}

/* Para dispositivos com largura menor que 768px */
@media (max-width: 768px) {
  .fc-button-group{
    display: none;
  }
  
  
  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .fc-toolbar-title{
    font-size: 1.2em !important;
    padding-top: 10px;
  }

  .fc-header-toolbar .fc-left,
  .fc-header-toolbar .fc-center,
  .fc-header-toolbar .fc-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  /* Organizar os botões dentro de cada grupo */
  .fc-header-toolbar .fc-left button,
  .fc-header-toolbar .fc-center button,
  .fc-header-toolbar .fc-right button {
    width: 100%;
    /* Faz os botões se esticarem para toda a largura */
    margin-bottom: 5px;
    /* Espaço entre os botões */
  }

  .fc-header-toolbar .fc-center {
    text-align: center;
  }

  .fc-view-timeGridWeek {
    display: none;
    /* Oculta a visualização semanal */
  }

  .fc-view-timeGridDay {
    display: block;
    /* Exibe a visualização de um único dia */
  }
}


.event-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 0.9rem;
}

.fc-col-header-cell-cushion {
  color: black;
  text-decoration: none;
}