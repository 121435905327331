.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

h2 {
  margin-bottom: 30px;
}


/* Adjust the spacing between the logo and the text if necessary */
.navbar-brand img {
  margin-right: 10px;
}

/* App.css */
.text-center h2 {
  font-size: 1.75rem;
  color: #333;
}

/* Add a border to the bottom of the navbar */
.navbar {
  border-bottom: 2px solid #ddd; /* Adjust color and thickness as needed */
}

/* Optional: Add margin to separate navbar from content */
.navbar + .container {
  margin-top: 20px; /* Adjust spacing between navbar and content */
}


/* Styling for the black transparent background section */
.black-background-section {
  background: rgba(0, 0, 0, 0.7); /* Black background with 70% opacity */
}

.black-green-section {
  background: #12747D; /* Black background with 70% opacity */
}

.black-background-section h2, 
.black-background-section p {
  color: white; /* White color for h2 and p elements */
}

.black-background-section .container {
  max-width: 100%; /* Adjust max-width if needed */
  padding: 2rem; /* Optional: Add padding for better spacing */
}

/* src/components/CookieBar.css */

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Black background with transparency */
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999; /* Ensures it stays on top */
}

.cookie-bar p {
  margin: 0;
  font-size: 14px;
}

.accept-btn {
  background-color: #12747D; /* Green button */
  color: white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.accept-btn:hover {
  background-color: #12747D; /* Darker green on hover */
}


/* src/components/Footer.css */
.footer {
  background-color: #333; /* Dark background */
  color: white;
}

.footer-section h5 {
  color: #fff;
  margin-bottom: 20px;
}

.footer-section p,
.footer-section ul {
  color: #ccc;
  font-size: 14px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links li a {
  color: #ccc;
  text-decoration: none;
}

.footer-links li a:hover {
  color: #fff;
}

.social-icons a {
  color: white;
  margin-right: 15px;
  font-size: 20px;
}

.social-icons a:hover {
  color: #ccc;
}

.footer .text-center {
  border-top: 1px solid #444;
  padding-top: 20px;
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}

.textVet{
  color: #12747D;
}
/* src/App.css */

/* Ensure the app container takes the full height of the viewport */
html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.content-wrap {
  flex: 1; /* Grow to fill the remaining space between the navbar and the footer */
  padding: 20px; /* Optional padding */
}

/* Ensure the footer stays at the bottom */
footer {
  background-color: #333; /* Footer background color */
  color: white;
  padding: 20px 0;
}


/* src/components/LanguageSwitcher.css */

/* Light-themed dropdown toggle button */
.language-switcher .dropdown-toggle {
  background-color: transparent; /* Transparent background to blend in with navbar */
  font-weight: 500; /* Medium font weight for balance */
  background-color: white ;
}

/* Hover and focus states for the dropdown toggle button */
.language-switcher .dropdown-toggle:focus,
.language-switcher .dropdown-toggle:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  color: #333; /* Darker text color on hover */
}

/* Dropdown menu with a lighter background and border */
.language-switcher .dropdown-menu {
  min-width: 100px; /* Adjust the dropdown width */
  background-color: #fff; /* White background for dropdown */
  border: 1px solid #ccc; /* Light gray border for a subtle effect */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Dropdown items */
.language-switcher .dropdown-item {
  color: #000; /* Text color */
  font-weight: 400; /* Normal font weight */
}

/* Hover state for dropdown items */
.language-switcher .dropdown-item:hover {
  background-color: #e0e0e0; /* Light background on hover */
  color: #000; /* Dark text color on hover */
}


/*Card Home*/
.cardheight{
  height: 200px !important;
}


.custom-modal .modal-dialog {
  max-width: 90vw; /* Adjust the width as needed, like 80vw or 100vw */
  width: 90vw;
}

.custom-modal .modal-body {
  max-height: 75vh; /* Make sure the body content doesn’t overflow */
  overflow-y: auto;
}

.custom-modal-width .modal-dialog {
  max-width: 800px; /* Ajuste a largura desejada */
}

@media (max-width: 768px) {
  .custom-modal-width .modal-dialog {
    max-width: 95%; /* Responsividade para telas menores */
  }
}


.card-spacing {
  margin-bottom: 20px; /* Adjust the value as needed */
}

.event-header {
  margin-bottom: 20px;
  font-size: 1.5rem;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
}

.fc .fc-non-business {
  background: #a7a5a5;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #12747D; /* Cor da marca d'água */
  font-size: 2rem; /* Tamanho da fonte */
  font-weight: bold;
  text-align: center;
  pointer-events: none; /* Permite clicar nos slots abaixo */
  z-index: 1;
}

.dscolor{
  color: #12747D !important; /* Cor da marca d'água */
}

.break-word {
  word-wrap: break-word; /* Para navegadores mais antigos */
  overflow-wrap: break-word; /* Para navegadores mais modernos */
  white-space: normal; /* Permite quebras de linha normais */
}

.text-container {
  width: 100%; /* Ou qualquer largura que você deseje */
  max-width: 300px; /* Defina um max-width se necessário */
  border: 1px solid #ccc; /* Apenas para visualização */
  padding: 10px; /* Espaçamento interno */
}

.btn-primary {
  background-color: #12747D !important;
  border-color: #12747D !important;
}

.btn-primary:hover {
  background-color: #0f5a61 !important;
  border-color: #0f5a61 !important;
}

/* Altera a cor do fundo dos botões de paginação */
.pagination .page-item .page-link {
  background-color: #12747D;
  border-color: #12747D;
  color: white; /* Cor do texto dentro do botão */
}

/* Altera a cor do botão ativo */
.pagination .page-item.active .page-link {
  background-color: #12747D;
  border-color: #12747D;
  color: white; /* Cor do texto dentro do botão ativo */
}

/* Altera a cor do botão de hover (quando o usuário passa o mouse sobre o botão) */
.pagination .page-item .page-link:hover {
  background-color: #106F6A; /* Cor de hover mais escura */
  border-color: #106F6A;
}

.a{
  color: black;
  text-decoration: none;
}

.margem{
  margin-bottom: 100px;
}

