.modern-form {
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  
  .form-input {
    border-radius: 8px;
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
  }
  
  .form-input:focus {
    box-shadow: 0 0 5px rgba(51, 153, 255, 0.5);
    border-color: #3399ff;
  }
  
  .submit-button {
    background: #3399ff;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background 0.3s ease;
  }
  
  .submit-button:hover {
    background: #1a8cff;
  }
  
  .submit-button:disabled {
    background: #cccccc;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  