  .imagem-heroi {
    width: 100%;
    height: auto;
    /* Mantém a proporção da imagem em todos os tamanhos */
  }

  /* Responsividade para Hero Section */
  @media (max-width: 768px) {
    .hero-text {
      top: 50%;
      /* Ajusta o posicionamento vertical */
      left: 50%;
      /* Mantém centralizado */
      transform: translate(-50%, -50%);
      font-size: 1rem;
      padding: 1rem;
      max-width: 50%;
      /* Ajusta o texto para ocupar toda a largura disponível */
    }

    .hero-text h1 {
      font-size: 1rem;
      /* Reduz o tamanho do texto em telas menores */
    }

    .hero-text h5 {
      font-size: 0.8rem;
      /* Reduz o subtítulo */
    }
  }

  /* Place text in the middle of the image */
  .hero-text {
    text-align: left;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    color: rgb(0, 0, 0);
  }

  /*Barra separadora*/
  .separator-container {
    background-color: #12747D;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
  }

  .separator-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  /*Cards*/
  .cardheight {
    height: 200px;
    transition: transform 0.3s;
    min-height: 300px;
  }

  .cardheight:hover {
    transform: scale(1.05);
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-text {
    color: #3f3f3f;
  }

  .home-img {
    transition: transform 0.3s;
  }

  .home-img:hover {
    transform: scale(1.05);
  }



  .icon-container {
    background: rgba(0, 0, 0, 0.39);
    /* Fundo semi-transparente para destacar os ícones e texto */
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    /* Efeitos de transformação e sombra */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    /* Sombra para dar profundidade */
  }

  .icon-container:hover {
    transform: translateY(-10px);
    /* Levanta o card ao passar o mouse */
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
    /* Aumenta a sombra ao passar o mouse */
  }

  .icon-container h5 {
    margin-top: 15px;
    font-size: 1.5rem;
    /* Tamanho do título */
    color: #fff;
    /* Cor do título */
  }

  .icon-container p {
    color: #ddd;
    /* Cor do texto */
  }

  @media (max-width: 768px) {
    .icon-container {
      text-align: center;
      /* Centra o texto em telas menores */
    }
  }


  /*About us*/
  .black-green-section {
    padding-bottom: 140px;
    border-radius: 15px;
  }


  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 10px;
    min-height: 330px;
    border-radius: 10px 10px 10px 0px;
    position: relative;
    overflow: hidden;
  }

  .icon-style {
    color: #12747D;
    margin-bottom: 15px;
  }

  .card-container h5 {
    font-weight: bold;
    margin-top: 25px;
    height: 60px;
  }

  .card-container p {
    margin-top: 15px;
  }